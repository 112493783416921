<template>
  <div class="prelims-participants-crews">
    <DownloadCSVButton
      v-if="battle.canDownload"
      :battle-id="battle.id"
      class="csv-btn"
    />
    <template v-if="isUserAdmin || !battle.isHiddenParticipants">
      <h3
        v-if="event.period !== 'future'"
        class="dancers-heading"
      >
        {{ crewsCheckedInCount }}/{{ crewsRegisteredCount }} Crews checked in
      </h3>
      <h3
        v-else
        class="dancers-heading"
      >
        {{ crewsRegisteredCount }} Crews registered
      </h3>
      <p
        v-if="battle.limit"
        class="battle-limit-heading"
      >
        {{ battleLimitText }}
      </p>
    </template>
    <section>
      <div
        v-if="!battle.isHiddenParticipants"
        class="search-crews"
      >
        <BKInput
          v-model="searchKeyword"
          :append-icon="mdiMagnify"
          hide-details
          label="Search crews"
        />
      </div>

      <BattleCrewRow
        v-for="crew in totalCrews"
        :key="crew.id"
        :class="{ highlighted: getIsCrewHighlighted(crew) }"
        :crew="crew"
        :is-highlighted="getIsCrewHighlighted(crew)"
        @open="openCrewModal"
      />
      <BattleWaitList
        :battle="battle"
        :wait-list-length="waitList.length"
      >
        <template #wait-list>
          <BattleCrewRow
            v-for="crew in waitList"
            :key="crew.id"
            :class="{ highlighted: getIsCrewHighlighted(crew) }"
            :crew="crew"
            :is-highlighted="getIsCrewHighlighted(crew)"
            @open="openCrewModal"
          />
        </template>
      </BattleWaitList>
      <div v-if="battle.isHiddenParticipants">
        <img
          v-for="item in 16"
          :key="item"
          alt="There are no crews"
          src="/img/battle/battle-list-Item-blurred.webp"
          style="width: 100%"
        />
      </div>

      <BattleEditCrewModal
        :battle="battle"
        :crew="selectedCrew"
        :event="event"
        :is-show-form.sync="isShowForm"
        @update:battle="$emit('update:battle', $event)"
        @reload:battle="$emit('reload:battle')"
        @update:crew="updateSelectedCrew"
      />

      <BattleCrewDetailsModal
        :battle="battle"
        :crew-id="crewDetails.id"
        :event="event"
        :is-show-modal.sync="crewDetails.isOpen"
        @join="$emit('join')"
        @update:battle="$emit('update:battle', $event)"
        @reload:battle="$emit('reload:battle')"
        @update:event="$emit('update:event', $event)"
        @reload:event="$emit('reload:event')"
      />
    </section>
  </div>
</template>

<script>
import Images from '@/mixins/image'
import { mapGetters } from 'vuex'

import BattleCrewDetailsModal from '@/components/Battle/Crew/BattleCrewDetailsModal.vue'
import BattleCrewRow from '@/components/Battle/Crew/BattleCrewRow.vue'
import BKInput from '@/components/AppShared/BKInput.vue'
import BattleEditCrewModal from '@/components/Battle/Info/BattleEditCrewModal.vue'
import { mdiMagnify } from '@mdi/js'
import BattleWaitList from '@/components/BattleWaitList.vue'
import { DownloadCSVButton } from '@/features/csv'

export default {
  name: 'BattleCrews',
  components: {
    DownloadCSVButton,
    BattleWaitList,
    BKInput,
    BattleCrewDetailsModal,
    BattleCrewRow,
    BattleEditCrewModal,
  },
  mixins: [Images],
  props: {
    event: {
      type: Object,
      required: true,
    },
    battle: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mdiMagnify,
      searchKeyword: '',
      loading: false,
      isShowForm: false,
      selectedCrew: {},
      crewDetails: {
        isOpen: false,
        id: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    totalCrews() {
      return this.battle.crews.filter((crew) => crew.name.toLowerCase().includes(this.searchKeyword.toLowerCase()))
    },
    waitList() {
      return this.battle.waitList.filter((crew) => crew.name.toLowerCase().includes(this.searchKeyword.toLowerCase()))
    },
    currentUserCrewId() {
      if (!this.getUserDB) return 0
      const currentUserCrew = this.battle.crews.find((cr) =>
        cr.participants.find((par) => par.id === this.getUserDB.id && par.confirmed)
      )
      if (!currentUserCrew) return 0

      return currentUserCrew.id
    },
    crewsRegisteredCount() {
      return this.battle.crews.length
    },
    crewsCheckedInCount() {
      return this.battle.crews.filter((crew) => crew.randId).length
    },
    battleLimitText() {
      if (this.battle.participationCapType === 'none') return ''
      return this.battle.participationCapType === 'checkIn'
        ? `Check-in cap: ${this.battle.limit}`
        : `Registration cap: ${this.battle.limit}`
    },
    isUserAdmin() {
      return (
        this.event.staff.admins.some((admin) => admin.id === this.getUserDB.id) ||
        this.event.staff.hosts.some((host) => host.id === this.getUserDB.id) ||
        this.event.staff.organizer.id === this.getUserDB.id
      )
    },
  },
  methods: {
    openCrewModal(value) {
      if (this.isAdmin) {
        this.isShowForm = true
        this.selectedCrew = value
      } else {
        this.crewDetails.isOpen = true
        this.crewDetails.id = value.id
      }
    },
    getIsCrewHighlighted(crew) {
      return crew.id === this.currentUserCrewId
    },
    updateSelectedCrew(value) {
      this.selectedCrew = value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/mixins';

.prelims-participants-crews {
  position: relative;
  background-color: var(--color-grey-100) !important;
}

.info {
  position: relative;
  background-color: var(--color-grey-700);

  @media (min-width: $screen-sm) {
    border-radius: 6px;
  }
}

.info-content-row {
  @media (min-width: $screen-sm) {
    display: flex;
    gap: 30px;
  }
}

.info-title {
  color: var(--color-dark-800);
  font-weight: bold;
}

.dancers-heading {
  @include font-h3;

  margin-bottom: 20px;
  padding: 0 20px;
}

.submit-button {
  width: 100%;
  max-width: 330px;
  height: 52px !important;
  margin-bottom: 20px;
  color: var(--color-white) !important;
}

.submit-text {
  display: block;
  color: var(--color-grey-300);

  &.err {
    color: #ff2d53;
  }
}

.search-crews {
  margin-bottom: 20px;
  padding: 0 20px;
}

.bk-button {
  margin: 0 20px;
}

.battle-limit-heading {
  margin-bottom: 20px;
  padding: 0 20px;
}

.csv-btn {
  max-width: 300px;
  margin-bottom: 24px;
}
</style>
