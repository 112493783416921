<template>
  <div class="date-time">
    <p class="date-time__title">Local date and time *</p>
    <v-row>
      <v-col cols="6">
        <v-menu
          v-model="menuDateStart"
          :close-on-content-click="false"
          min-width="290px"
          offset-overflow
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateStart"
              :append-icon="mdiCalendarCheckOutline"
              background-color="#fff"
              class="date-time__date mb-n6"
              hide-details
              label="Start date"
              outlined
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateStart"
            class="date-time__menu"
            no-title
            scrollable
            @input="menuDateStart = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="timeStart"
          :append-icon="mdiClockOutline"
          background-color="#fff"
          class="date-time__time mb-n6"
          outlined
          type="time"
        />
      </v-col>
      <v-col cols="6">
        <v-menu
          v-model="menuDateEnd"
          :close-on-content-click="false"
          min-width="290px"
          offset-overflow
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateEnd"
              :append-icon="mdiCalendarCheckOutline"
              background-color="#fff"
              class="date-time__date"
              hide-details
              label="End date"
              outlined
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateEnd"
            class="date-time__menu"
            no-title
            scrollable
            @input="menuDateEnd = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="timeEnd"
          :append-icon="mdiClockOutline"
          background-color="#fff"
          class="date-time__time"
          outlined
          type="time"
        />
      </v-col>
    </v-row>
    <div class="date-time__navigation navigation">
      <div class="navigation__wrap-dots mt-6">
        <span
          v-for="i in 5"
          v-show="isNew"
          :key="i"
          :class="i === currentDotNav ? 'navigation__dot--active' : ''"
          class="navigation__dot"
        />
      </div>
      <p
        v-show="error"
        class="navigation__text-error text-center mb-2"
      >
        {{ error }}
      </p>
      <div class="navigation__wrap-btn mb-10">
        <div class="d-flex flex-column flex-sm-row-reverse">
          <v-btn
            :loading="loadingNextBtn"
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="handlerClickNext"
          >
            {{ backStep ? 'save' : 'next' }}
          </v-btn>
          <v-btn
            class="navigation__btn"
            color="var(--color-blue-800)"
            height="48"
            outlined
            tile
            @click="backStep ? handlerClickCancelEdit() : handlerClickBack()"
          >
            {{ backStep ? 'cancel' : 'back' }}
          </v-btn>
        </div>
        <v-btn
          v-if="!backStep"
          block
          class="navigation__btn-cancel"
          color="var(--color-blue-800)"
          height="48"
          max-width="194"
          text
          @click="store.dispatch('toggleCancelEventCreation')"
        >
          cancel
        </v-btn>
      </div>
    </div>
    <CreateEventCancel
      :event="eventData"
      @saveAsDraft="$emit('saveAsDraft')"
    />
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getDateFormattedForPicker, getTimeFormatted } from '@/services/date'
import CreateEventCancel from '@/pages/CreateEventProcessingPage/ui/CreateEventCancel.vue'
import { mdiCalendarCheckOutline, mdiClockOutline } from '@mdi/js'
import { EventDB } from '@/enitites/event'
import { CreateEventFormName } from '@/pages/CreateEventProcessingPage/model/types'
import { computed, inject, ref } from 'vue'

const props = defineProps<{
  isNew: boolean
  screen: CreateEventFormName
  eventData: EventDB
  backStep: boolean
}>()

const emit = defineEmits<{
  (e: 'update:screen', value: CreateEventFormName): void
  (e: 'updateEvent'): void
  (e: 'saveAsDraft'): void
}>()

const menuDateStart = ref(false)
const menuDateEnd = ref(false)
const dateStart = ref(getDateFormattedForPicker(props.eventData.dateStart) || '')
const dateEnd = ref(getDateFormattedForPicker(props.eventData.dateEnd) || '')
const timeStart = ref(getTimeFormatted(props.eventData.dateStart) || '12:00')
const timeEnd = ref(getTimeFormatted(props.eventData.dateEnd) || '12:00')
const loadingNextBtn = ref(false)
const currentDotNav = ref(2)
const error = ref<string | null>(null)

function createDateTime(date: string, time: string) {
  return new Date(`${date.replace(/-/g, '/')} ${time}`)
}

const isInvalidDate = computed(() => {
  return (
    createDateTime(dateEnd.value, timeEnd.value).getTime() < createDateTime(dateStart.value, timeStart.value).getTime()
  )
})
const injectedIsClickBtnNext = inject<{
  updateIsClickBtnNext: (value: boolean) => void
}>('isClickBtnNext')

function handlerClickNext() {
  error.value = null

  if (isInvalidDate.value) {
    error.value = "End Date can't be before the Start Date"
  }

  props.eventData.dateStart = createDateTime(dateStart.value, timeStart.value)
  props.eventData.dateEnd = createDateTime(dateEnd.value, timeEnd.value)

  loadingNextBtn.value = true
  emit('updateEvent')
  injectedIsClickBtnNext?.updateIsClickBtnNext(true)
  emit('update:screen', props.backStep ? 'CreateEventTotal' : 'CreateEventTiers')
  loadingNextBtn.value = false
}

function handlerClickBack() {
  injectedIsClickBtnNext?.updateIsClickBtnNext(false)
  emit('update:screen', 'CreateEventInfo')
}

function handlerClickCancelEdit() {
  emit('update:screen', 'CreateEventTotal')
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';
@import '@/assets/style/navigationEventCreate';

.date-time {
  max-width: 400px;
  margin: 60px auto 0;

  &__title {
    margin: 12px 0;
    color: var(--color-dark-800);
  }
}

.date-time__date :deep(.v-input__append-inner),
.date-time__time :deep(.v-input__append-inner) {
  position: absolute;
  right: 10px;
  background: white;
  pointer-events: none;
}
</style>
