export const getDateFormatted = (date) => date.toLocaleDateString('en-US')

export function getTimeFormatted(date) {
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) return ''

  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}

export function getTimeFormatted12Hours(date) {
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) return ''

  let hours = date.getHours()
  const minutes = date.getMinutes()
  const isPM = hours >= 12

  hours = hours % 12 || 12

  const minutesStr = String(minutes).padStart(2, '0')

  return `${hours}:${minutesStr} ${isPM ? 'PM' : 'AM'}`
}

export function getDateFormattedForPicker(date) {
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) return ''

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // months are 0-based
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function getDateFormattedHuman(date, isLongForm = false) {
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
    return ''
  }

  const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const longMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const year = date.getFullYear()
  const monthIndex = date.getMonth()
  const day = String(date.getDate()).padStart(2, '0') // "01", "26", etc.

  const monthName = isLongForm ? longMonths[monthIndex] : shortMonths[monthIndex]

  return `${monthName} ${day}, ${year}`
}

export const getDateRangeFormatted = (dateStart, dateEnd, isLongForm = true) => {
  if (dateStart === dateEnd) return getDateFormattedHuman(dateStart, isLongForm)
  return `${getDateFormattedHuman(dateStart, isLongForm)} - ${getDateFormattedHuman(dateEnd, isLongForm)}`
}

export const getTimeRangeFormatted = (dateStart, dateEnd) => {
  if (getTimeFormatted12Hours(dateStart) === getTimeFormatted12Hours(dateEnd)) {
    return getTimeFormatted12Hours(dateStart)
  }

  return `${getTimeFormatted12Hours(dateStart)} - ${getTimeFormatted12Hours(dateEnd)}`
}

export const isZeroDate = (date) => date.getFullYear() > 2000
