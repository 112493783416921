<template>
  <div class="home">
    <section class="home__big-logo big-logo">
      <img
        alt="Break Konnect"
        class="big-logo__logo"
        src="/img/break-konnect.svg"
      />
      <h4 class="big-logo__title d-flex align-center flex-column flex-sm-row">
        <span class="big-logo__title-word">Learn</span>
        <span class="big-logo__title-dot">•</span>
        <span class="big-logo__title-word">Train</span>
        <span class="big-logo__title-dot">•</span>
        <span class="big-logo__title-word">Organize</span>
      </h4>
      <div class="events-button-container">
        <router-link :to="{ name: 'EventsGrid' }">
          <v-btn
            class="view-events-button"
            color="var(--color-white)"
            height="52"
            width="224"
          >
            View events
          </v-btn>
        </router-link>
        <v-btn
          class="create-event-button"
          color="var(--color-white)"
          height="52"
          outlined
          width="224"
          @click="createEvent"
        >
          Create event
        </v-btn>
      </div>
    </section>
    <section class="home__featured featured">
      <div class="featured__wrap">
        <h2 class="section-title">Featured Events</h2>
        <ul class="featured__list">
          <li
            v-for="(evt, index) in featuredEvents"
            :key="index"
            class="featured__list-item"
          >
            <EventPreviewBaseCard
              :preview-event="evt"
              class="event-card"
            />
          </li>
        </ul>
        <div class="events-button-container">
          <router-link :to="{ name: 'EventsGrid' }">
            <v-btn
              class="view-events-button"
              color="var(--color-white)"
              height="52"
              width="224"
            >
              View events
            </v-btn>
          </router-link>
          <v-btn
            class="create-event-button"
            color="var(--color-white)"
            height="52"
            outlined
            width="224"
            @click="createEvent"
          >
            Create event
          </v-btn>
        </div>
      </div>
    </section>
    <section
      id="workshops"
      class="home__workshops workshops"
    >
      <h2 class="section-title">Workshops</h2>
      <div class="workshops__subtitle">Coming soon</div>
      <ul class="workshops__list">
        <li
          v-for="(instructor, index) in instructors"
          :key="index"
          class="workshops__list-item d-flex col pa-lg-5 pa-4"
        >
          <MembersPlayerCard :user="instructor" />
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MembersPlayerCard from '@/components/MembersPlayerCard.vue'
import api from '@/api'
import { EventPreviewBaseCard } from '@/features/event-card'
import { eventApi } from '@/enitites/event'

export default {
  components: {
    EventPreviewBaseCard,
    MembersPlayerCard,
  },
  data() {
    return {
      instructors: [],
      events: [],
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    featuredEvents() {
      return this.events.slice(0, 3)
    },
  },
  async created() {
    const [nowEvents, futureEvents, workShops] = await Promise.all([
      this.getEventsChunk('now'),
      this.getEventsChunk('future'),
      this.fetchWorkshops(),
    ])

    this.events = [...(nowEvents || []), ...(futureEvents || [])]
    this.instructors = [...(workShops || [])]
  },
  methods: {
    async createEvent() {
      if (!this.getUserDB) {
        this.$router.push({
          name: 'Signup',
          query: { redirect: this.$route.path },
        })
        return
      }
      this.$router.push({ name: 'CreateEvent' })
    },
    async getEventsChunk(period) {
      return eventApi.getEvents({
        pageNumber: 0,
        pageSize: 3,
        tab: period,
        sortBy: 'date-asc',
      })
    },
    async fetchWorkshops() {
      return api.workshops.getWorkshops()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/mixins';

.row {
  padding: 0 50px;
}

.section-title {
  @include font-h2;

  color: var(--color-white);
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
}

.home {
  background-color: var(--color-black);
}

.big-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 232px;
  padding-bottom: 32px;
  background: var(--color-blue-900) url('/img/background-home.jpg') 50% 50% / cover no-repeat;

  @media (max-width: 600px) {
    padding-top: 92px;
  }

  &__logo {
    width: 178px;
    height: auto;

    @media (max-width: 600px) {
      width: 146px;
    }
  }

  &__title {
    margin: 32px 0 40px;
    padding: 0;
    color: var(--color-orange-500);

    @include font-h4;

    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }

  &__title-word {
    letter-spacing: 8px;
    text-transform: uppercase;

    @media (max-width: 600px) {
      padding-top: 8px;
    }
  }

  &__title-dot {
    margin: 0 20px 0 15px;

    @media (max-width: 600px) {
      display: none;
    }
  }
}

.events-button-container {
  @media (max-width: $screen-sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .view-events-button,
  .create-event-button {
    font-weight: var(--font-weight-bold);

    @include font-body2;
  }

  .create-event-button {
    margin-left: 40px;

    @media (max-width: $screen-sm) {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}

.featured {
  padding-top: 60px;
  background-image: url('/img/background2-home.jpg');
  background-size: cover;

  &__wrap {
    background: linear-gradient(to top, rgb(0 0 0 / 100%), rgb(0 0 0 / 56%) 18.49%, rgb(0 0 0 / 0%) 39.08%);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    margin-top: 40px;
    padding: 0 30px;
    list-style: none;

    @media (max-width: $screen-md) {
      padding: 0;
    }
  }

  &__list-item {
    justify-content: center;
    box-sizing: content-box;

    .event-card {
      max-width: 340px;
    }

    @media (min-width: $screen-lg) {
      max-width: 420px;
    }
  }

  .events-button-container {
    margin-top: 24px;
    text-align: center;
  }
}

.workshops {
  padding: 156px 0 80px;
  background: #010101;

  @media (min-width: $screen-lg) {
    padding: 80px 0;
  }

  &__subtitle {
    margin-top: 8px;
    color: var(--color-grey-600);
    text-align: center;

    @media (max-width: $screen-sm) {
      margin-top: 4px;
    }
  }

  &__list {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 24px;
    padding: 0 30px;

    @media (max-width: $screen-md) {
      padding: 0;
    }
  }

  &__list-item {
    justify-content: center;
    box-sizing: content-box;
    max-width: 305px;

    @media (max-width: $screen-md) {
      max-width: 340px;
    }
  }
}
</style>
