import { isZeroDate } from '@/services/date'

export const initGetEventResp = (event) => {
  if (!event) return event
  const data = { ...event }
  data.dateStart = new Date(data.dateStart)
  data.dateEnd = new Date(data.dateEnd)

  // eslint-disable-next-line no-use-before-define
  data.period = getEventPeriod(data.dateStart, data.dateEnd)

  if (data.tiers) {
    const deltaCost = data.tiers.reduce((acc, tier) => acc + tier.cost, 0)
    data.isFree = deltaCost === 0

    data.tiers = data.tiers.map((t) => {
      const tier = { ...t }
      tier.cost /= 100

      tier.ticketCost = tier.cost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

      tier.taxes /= 100
      tier.ticketTaxes = tier.taxes.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

      tier.fees /= 100
      tier.ticketFees = tier.fees.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

      tier.totalSum /= 100
      tier.ticketTotalSum = tier.totalSum.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      return tier
    })
  }

  if (data.tickets) {
    // eslint-disable-next-line no-use-before-define
    data.tickets = initTickets(data.tickets)
  }

  if (data.attendees) {
    data.attendees = data.attendees.map((a) => {
      // eslint-disable-next-line no-param-reassign
      a.checkedInAt = new Date(a.checkedInAt)
      // eslint-disable-next-line no-param-reassign
      a.checkedIn = !!isZeroDate(a.checkedInAt)
      return a
    })
  }

  return data
}

const getEventPeriod = (dateStart, dateEnd) => {
  if (dateEnd < new Date()) return 'past'
  if (new Date() < dateStart) return 'future'
  return 'now'
}

export const initUpdateEventReq = (event) => {
  if (!event) return event
  const data = { ...event }
  const isSameDay = data.dateStart.getDate() === data.dateEnd.getDate()
  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }
  const timeOptions = { hour: '2-digit', minute: '2-digit' }

  // Apr 5, 2023 - Apr 8, 2023
  data.dateFlat = isSameDay
    ? data.dateStart.toLocaleDateString('en-US', dateOptions)
    : `${data.dateStart.toLocaleDateString('en-US', dateOptions)} - ${data.dateEnd.toLocaleDateString(
        'en-US',
        dateOptions
      )}`

  // 10:00 AM - 5:00 PM
  data.timeFlat = `${data.dateStart.toLocaleTimeString('en-US', timeOptions)} - ${data.dateEnd.toLocaleTimeString(
    'en-US',
    timeOptions
  )}`
  data.dateEnd = data.dateEnd.toISOString()
  data.dateStart = data.dateStart.toISOString()

  if (!data.tiers) return data

  data.tiers = data.tiers.map((t) => {
    const tier = { ...t }
    tier.cost = Math.round(t.cost * 100)
    tier.taxes = Math.round(t.taxes * 100)
    tier.fees = Math.round(t.fees * 100)
    tier.totalSum = Math.round(t.totalSum * 100)
    return tier
  })

  return data
}

export const initBattleResp = (battle) => {
  if (!battle) return battle
  // eslint-disable-next-line no-param-reassign
  battle.startDate = new Date(battle.startDate)

  // eslint-disable-next-line max-len,no-param-reassign
  battle.permissions.registerButtonVisible = !!Object.keys(battle.permissions).filter(
    (pkey) => battle.permissions[pkey]
  ).length

  if (battle.crews.length) {
    // eslint-disable-next-line no-param-reassign,no-use-before-define
    battle.crews = initBattleCrews(battle.crews)
    // eslint-disable-next-line no-param-reassign,no-use-before-define
    battle.waitList = initBattleCrews(battle.waitList)
  }

  if (battle.participants.length) {
    // eslint-disable-next-line no-param-reassign,no-use-before-define
    battle.participants = initBattleParticipants(battle.participants)
    // eslint-disable-next-line no-param-reassign,no-use-before-define
    battle.waitList = initBattleParticipants(battle.waitList)
  }

  if (battle.orgLegalDocs) {
    // eslint-disable-next-line no-param-reassign
    battle.orgLegalDocs.waiverSignedAt = new Date(battle.orgLegalDocs.waiverSignedAt)
    // eslint-disable-next-line no-param-reassign
    battle.orgLegalDocs.agreementSignedAt = new Date(battle.orgLegalDocs.agreementSignedAt)
  }

  return battle
}

export const initUpdateBattleReq = (battle) => {
  if (!battle) return battle
  const data = { ...battle }
  data.startDate = new Date(data.startDate).toISOString()
  return data
}

export const initPrelimsResp = (prelims) => {
  // If there are no crews in the prelims, we need to add empty 1 round
  if (prelims?.crews) {
    // eslint-disable-next-line no-param-reassign
    prelims.crews = prelims.crews.map((crew) => {
      // eslint-disable-next-line no-param-reassign
      if (!Object.keys(crew.prelims.rounds).length) crew.prelims.rounds = { 1: [] }

      return crew
    })
  }

  // If there are no participants in the prelims, we need to add empty 0 round
  if (prelims?.participants) {
    // eslint-disable-next-line no-param-reassign
    prelims.participants = prelims.participants.map((p) => {
      // eslint-disable-next-line no-param-reassign
      if (!Object.keys(p.prelims.rounds).length) p.prelims.rounds = { 0: [] }

      return p
    })
  }

  return prelims
}

export const initTickets = (tickets) =>
  tickets.map((t) => {
    const ticket = { ...t }

    ticket.taxes /= 100
    ticket.ticketTaxes = ticket.taxes.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

    ticket.fees /= 100
    ticket.ticketFees = ticket.fees.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

    ticket.sum /= 100
    ticket.ticketSum = ticket.sum.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    return ticket
  })

const initBattleCrews = (crews) =>
  crews.map((crew) => {
    // eslint-disable-next-line no-param-reassign,no-use-before-define
    crew.participants = initBattleParticipants(crew.participants)
    // crew.judging = crew.judging.map(j => {
    //   j.withStar = j.points % 1 !== 0
    //   return j
    // })

    return crew
  })

// TODO: remove this function and place the logic on backend part
export const initBattleParticipants = (participants) =>
  participants.map((p) => {
    // eslint-disable-next-line no-param-reassign
    p.checkedInAt = new Date(p.checkedInAt)
    // eslint-disable-next-line no-param-reassign
    p.checkedIn = !!isZeroDate(p.checkedInAt)
    // eslint-disable-next-line no-param-reassign
    p.confirmedAt = new Date(p.confirmedAt)
    // eslint-disable-next-line no-param-reassign
    p.confirmed = !!isZeroDate(p.confirmedAt)
    if (p.waiverSignedAt) {
      // eslint-disable-next-line no-param-reassign
      p.waiverSignedAt = new Date(p.waiverSignedAt)
      // eslint-disable-next-line no-param-reassign
      p.waiverSigned = !!isZeroDate(p.waiverSignedAt)
    }
    if (p.agreementSignedAt) {
      // eslint-disable-next-line no-param-reassign
      p.agreementSignedAt = new Date(p.agreementSignedAt)
      // eslint-disable-next-line no-param-reassign
      p.agreementSigned = !!isZeroDate(p.agreementSignedAt)
    }
    return p
  })
